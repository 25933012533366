<template>
  <div class="container">
    <h1>Homestay Listings</h1>
    <div class="filters">
      <div class="filter">
        <label>City:</label>
        <div class="filter-options">
          <button
            v-for="city in cities"
            :key="city"
            :class="{ selected: city === filters.city }"
            @click="selectCity(city)"
          >
            {{ city }}
          </button>
        </div>
      </div>
      <div class="filter">
        <label>District:</label>
        <div class="filter-options">
          <div v-for="district in districts" :key="district">
            <input type="checkbox" :id="`district-${district}`" :value="district" v-model="filters.districts">
            <label :for="`district-${district}`">{{ district }}</label>
          </div>
        </div>
      </div>
      <div class="filter">
        <label>Score:</label>
        <div class="filter-options">
          <div v-for="score in scores" :key="score">
            <input type="checkbox" :id="`score-${score}`" :value="score" v-model="filters.score">
            <label :for="`score-${score}`">{{ score }}</label>
          </div>
        </div>
      </div>
      <div class="filter">
        <label>Stars:</label>
        <div class="filter-options">
          <div v-for="star in stars" :key="star">
            <input type="checkbox" :id="`star-${star}`" :value="star" v-model="filters.stars">
            <label :for="`star-${star}`">{{ star }}</label>
          </div>
        </div>
      </div>
      <div class="filter">
        <label>Opening Years:</label>
        <div class="filter-options">
          <div v-for="year in openingYears" :key="year">
            <input type="checkbox" :id="`year-${year}`" :value="year" v-model="filters.openingYears">
            <label :for="`year-${year}`">{{ year }}年内</label>
          </div>
        </div>
      </div>
    </div>
    <button @click="fetchHomestays">Search</button>
    <div class="homestay-listings">
      <div v-for="homestay in homestays" :key="homestay.id" class="homestay-entry" :class="homestay.colorClass">
        <div class="homestay-header">
          <div class="header-content">
            <div class="homestay-logo">
              <img :src="homestay.logo" alt="Logo" />
            </div>
            <div class="homestay-details">
              <div class="homestay-title">
                <b>{{ homestay.homestay_name }}</b>
                <div class="homestay-diqu">{{ homestay.diqu }}</div>
              </div>
              <div class="homestay-info">
                <div class="info-item">
                <!--  <img class="icon" alt="" src="location_on_white_24dp_1.svg" /> -->
                  <span>{{ homestay.xcstar }}</span>
                </div>
                <div class="info-item">
               <!--   <img class="icon" alt="" src="schedule_white_24dp_1.svg" /> -->
                  <span>{{ homestay.score }}</span>
                </div>
                <div class="info-item">
              <!--    <img class="icon" alt="" src="history_white_24dp_1.svg" /> -->
                  <span> {{ homestay.last_renovation }}</span>
                </div>
           
              </div>
              <div class="homestay-description">
                {{ homestay.description }}
              </div>
            </div>
          </div>
          <div class="homestay-view">
            <button @click="incrementHotAndNavigate(homestay.id, homestay.link)">View</button>
          <!--  <img class="send-icon" alt="" src="send.svg" /> -->
          </div>
        </div>
        <div class="homestay-footer">
          <div class="footer-left">
            <img class="icon" alt="" src="history_white_24dp_1.svg" />
            <span>热度: {{ homestay.hot }}</span>
          </div>
          <div class="footer-right">
            <b class="price">{{ homestay.price }}/晚</b>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      homestays: [],
      cities: [],
      districts: [],
      selectedCity: '',
      scores: ["4.8", "5.0", "4.9"],
      stars: ['4', '5'],
      openingYears: [1, 2, 3, 4, 5],
      filters: {
        city: '',
        districts: [],
        score: [],
        stars: [],
        openingYears: []
      }
    };
  },
  created() {
    this.fetchCities();
  },
  methods: {
    fetchCities() {
      axios.get('http://ms.genesiu.com:5014/cities')
        .then(response => {
          this.cities = response.data;
        })
        .catch(error => console.error('Error fetching cities:', error));
    },
    selectCity(city) {
      this.selectedCity = city;
      this.filters.city = city;
      this.fetchDistricts(city);
    },
    fetchDistricts(city) {
      axios.get(`http://ms.genesiu.com:5014/districts?city=${encodeURIComponent(city)}`)
        .then(response => {
          this.districts = response.data;
        })
        .catch(error => console.error('Error fetching districts:', error));
    },
    fetchHomestays() {
      let params = new URLSearchParams();
      if (this.filters.city) {
        params.append('city', this.filters.city);
      }
      this.filters.districts.forEach(district => {
        params.append('districts', district);
      });
      this.filters.score.forEach(score => {
        params.append('score', score);
      });
      this.filters.stars.forEach(star => {
        params.append('stars', star);
      });
      this.filters.openingYears.forEach(year => {
        params.append('openingYears', year);
      });

      axios.get(`http://ms.genesiu.com:5014/homestays?${params.toString()}`)
        .then(response => {
          this.homestays = response.data;
          // Assign a color class to each homestay based on certain criteria
          this.homestays.forEach((homestay, index) => {
            homestay.colorClass = this.getColorClass(index);
          });
        })
        .catch(error => {
          console.error('Error fetching homestays:', error);
          alert('Failed to fetch homestays. Check console for details.');
        });
    },
    getColorClass(index) {
      const colors = ['blue', 'red', 'yellow'];
      return colors[index % colors.length];
    },
    incrementHotAndNavigate(id, link) {
      axios.post('http://ms.genesiu.com:5014/increment_hot', { id: id })
        .then(() => {
          window.location.href = link;
        })
        .catch(error => {
          console.error('Error incrementing hot count:', error);
          alert('Failed to update hot count. Check console for details.');
        });
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Satoshi:wght@400;500;700&display=swap');

body {
  font-family: 'Satoshi', sans-serif;
  background-color: #f4f4f9;
  color: #333;
  margin: 0;
  padding: 20px;
  line-height: normal;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 8px;
}

.filters {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.filter {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align labels to the left */
}

.filter label {
  margin-bottom: 5px;
  font-weight: bold;
}

.filter-options {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

button, input[type="checkbox"] {
  margin-right: 5px;
}

button {
  padding: 6px 12px;
  background-color: #e0e0e0; /* Default background color for unselected buttons */
  color: #333; /* Default text color for unselected buttons */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button.selected {
  background-color: #0056b3; /* Background color for selected buttons */
  color: white; /* Text color for selected buttons */
}

button:hover {
  background-color: #003580;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  background-color: #fff;
  border-bottom: 1px solid #eee;
  padding: 15px;
  margin-top: 10px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  border-radius: 5px;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem; /* Increase font size for the title */
}

.homestay-listings {
  margin-top: 20px;
}

.homestay-entry {
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 20px;
  position: relative;
}

.homestay-entry.blue {
  background-color: #001f3f;
  color: #fff;
}

.homestay-entry.red {
  background-color: #FF4136;
  color: #fff;
}

.homestay-entry.yellow {
  background-color: #FFDC00;
  color: #333;
}

.homestay-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 8px 8px 0 0;
}

.header-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.homestay-logo img {
  width: 48px;
  height: 48px;
  border-radius: 8px;
}

.homestay-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.homestay-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
/*  font-weight: 700; */
}

.homestay-title b {
  font-size: 14px; /* Decrease font size */
}

.homestay-diqu {
  font-size: 12px; /* Decrease font size */
}

.homestay-info {
  display: flex;
  gap: 10px;
}

.homestay-info .info-item {
  display: flex;
  align-items: center;
  gap: 4px;
  border: 1px solid white; /* White border */
  padding: 4px; /* Add padding for better appearance */
  border-radius: 5px; /* Rounded corners */
  margin-right: 10px; /* Add margin between info items */
}

.homestay-info .icon {
  width: 16px;
  height: 16px;
}

.homestay-description {
  margin-top: 10px;
  line-height: 1.5;
}

.homestay-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  color: #000;
  padding: 10px 20px;
  border-radius: 0 0 8px 8px;
}

.footer-left {
  display: flex;
  align-items: center;
  gap: 4px;
}

.footer-right {
  font-size: 18px;
  font-weight: 700;
}

.price {
  font-size: 18px;
  font-weight: 700;
}

.send-icon {
  width: 16px;
  height: 16px;
  margin-left: 5px;
}

.homestay-view {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
}
</style>
